.container-code-editor__qxcy {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex !important;
  align-items: center;
  justify-content: center;
  background-color: #2b2b2b;
  overflow: hidden !important;
}
.container-code-editor__qxcy .line-numbers__qxcy {
  flex: 1;
  box-sizing: border-box;
  min-width: 60px;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-self: start;
  padding: 1rem;
  text-align: right;
  font-size: 1em;
  color: #666666;
  overflow-y: hidden;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.container-code-editor__qxcy .line-numbers__qxcy::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: transparent;
}
.container-code-editor__qxcy .line-numbers__qxcy > * {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.container-code-editor__qxcy .code-editor__qxcy {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}
.container-code-editor__qxcy .code-editor__qxcy ::-webkit-scrollbar-corner {
  background: transparent;
}
.container-code-editor__qxcy .code-editor__qxcy ::-webkit-scrollbar {
  background-color: transparent;
  width: 5px;
  height: 5px;
}
.container-code-editor__qxcy .code-editor__qxcy ::-webkit-scrollbar-thumb {
  background: #5c5c5c;
}
.container-code-editor__qxcy .code-editor__qxcy > * {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.container-code-editor__qxcy .code-editor__qxcy .code-editor__textarea__qxcy,
.container-code-editor__qxcy .code-editor__qxcy .code-editor__hlcode__qxcy {
  scrollbar-color: #5c5c5c transparent;
  scrollbar-width: thin;
  font-size: 1em;
  padding: 1rem;
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: scroll;
  box-sizing: border-box;
}
.container-code-editor__qxcy .code-editor__qxcy .code-editor__textarea__qxcy {
  top: 0;
  border: none;
  white-space: pre;
  resize: none;
  position: absolute;
  color: transparent;
  background-color: transparent;
}
.container-code-editor__qxcy .code-editor__qxcy .code-editor__textarea__qxcy:focus {
  outline: none;
}

/*# sourceMappingURL=index.css.map */
